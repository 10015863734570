import React, { useState } from 'react'
import { GiPerspectiveDiceSixFacesOne } from 'react-icons/gi'

const iquotes = require('iquotes');


const Quote = () => {

    const [randomQuote, setQuote] = useState(iquotes.random('dev'));
    const GetRandomQuote = () => {
        setQuote(iquotes.random('dev'));
    }

    return (
        <div className="container">
            <div className='header__quote'>
                <h3>"{randomQuote.quote}"</h3>
                <h5>- {randomQuote.author}</h5>

                <a className='get-random-quote'><GiPerspectiveDiceSixFacesOne onClick={() => { GetRandomQuote() }} /></a>

            </div >
        </div>
    )

}

export default Quote