import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next'

import './header.scss'
import CTA from './CTA'
import Quote from './Quote'

const sizes = [
  { width: '80px', height: '80px' },
  { width: '90px', height: '90px' },
  { width: '100px', height: '100px' },
  { width: '120px', height: '120px' },
  { width: '140px', height: '140px' },
  { width: '160px', height: '160px' },
  { width: '180px', height: '180px' },
  { width: '200px', height: '200px' },
  { width: '230px', height: '230px' },
  { width: '280px', height: '280px' },
  { width: '300px', height: '300px' },
  { width: '330px', height: '330px' },
  { width: '330px', height: '350px' },
  { width: '330px', height: '370px' },
];

// Rastgele genişlik ve yükseklik değerleri üreten fonksiyon
const getRandomSize = () => {
  const randomIndex = Math.floor(Math.random() * sizes.length);
  return sizes[randomIndex];
};

function importAll(r) {
  let images = {};
  r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
  return images;
}

const images = importAll(require.context('../../assets/skillsImages/', false, /\.(png|jpe?g|svg)$/));

const expImages = Object.values(images);





const Header = () => {
  const { t } = useTranslation();
  const [isReversed, setIsReversed] = useState(false);

  let doubleExpImages = [...expImages, ...expImages];

  if (isReversed) {
    doubleExpImages = doubleExpImages.reverse();
  }

  const handleImageClick = () => {
    setIsReversed(prevIsReversed => !prevIsReversed);
  };

  const boxes = doubleExpImages.map((image, index) => {
    const size = getRandomSize();
    return (
      <div key={index} className="black-box" style={size} onClick={handleImageClick}>
        <img src={image} alt="" />
      </div>
    );
  });

  return (
    <header>
      <div className='background-wrapper'>
        <div className="container header__container">

          <div className="black-boxes-container">
            {boxes}

          </div>
          <div className='gradient-blur'>
            <div></div>
            <div></div>
            
            
          </div>
          <div className="text-content">
            {/* <h5>{t('header.greetings')}</h5> */}
            <h1 className='text-name'>Ramazan Altuntepe.</h1>
            <h5 className="text-light"> Backend Developer</h5>
            <CTA />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;