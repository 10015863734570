import React from 'react';

function importAll(r) {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
}

const images = importAll(require.context('../../assets/skillsImages/', false, /\.(png|jpe?g|svg)$/));

const expImages = Object.values(images);

function SkillsSlider() {

    return (
        <div className="slider-container">
            <div className="slider-inner">
                {expImages.map((image, index) => (
                    <div key={index} className="skills__slider-wrapper">
                        <img src={image} alt="" />
                    </div>
                ))}
            </div>
        </div>
    );
}

export default SkillsSlider;