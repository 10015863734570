import React from 'react'
import './portfolio.scss'
const GithubProfileBadge = ({ avatar,login,  bio, url }) => {
  return (
    <div class="outer-wrapper">
      <div className="portfolio__profile__badge">

        <div className='portfolio__gh-image-wrapper '>
          <a href={url}>
            <img src={avatar} alt="github avatar" />
          </a>
        </div>
        <div className='portfolio__github-data'>
          <a href={url}>github.com/{login}</a>
          <h5>{bio}</h5>
        </div>
      </div>
    </div>
  )
}

export default GithubProfileBadge