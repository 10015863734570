import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import './about.scss'


function About() {
  const { t } = useTranslation();

  return (
    <section id="about">
      <h2>{t('about.title')}</h2>
      <div className='container about__container'>
        <div className="translator">
        </div>
        <div className="arcticle-wrapper">
          <article>
            <hr />
            <br />
            {t('about.body')}
            <br />
            <br />
          </article>
        </div>
      </div>

    </section>
  )
}

export default About