import React, { useState, useEffect } from 'react'
import './portfolio.scss'
import PortfolioItemsGrid from './PortfolioItemsGrid'
import GithubProfileBadge from './GithubProfileBadge'
import axios from 'axios';
import { useTranslation } from 'react-i18next'

const Portfolio = () => {

    const [userData, setUserData] = useState(null);
    const username = 'ramalt';
    const {t} = useTranslation();
    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await axios.get(`https://api.github.com/users/${username}`);
                setUserData(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchUserData();
    }, [username]);

    return (
        <section id='portfolio'>
            <h5>{t('portfolio.secondaryTitle')}</h5>
            <h2>{t('portfolio.title')}</h2>


            <div className='container portfolio__container'>
                <GithubProfileBadge avatar={userData && userData.avatar_url} url={userData && userData.html_url} login={userData && userData.login} bio={userData && userData.bio} />
                <PortfolioItemsGrid/>

            </div>

        </section>
    )
}

export default Portfolio