import { React, useState } from 'react'

import { AiFillHome, AiFillRead, AiFillMessage } from 'react-icons/ai'
import { BiSolidUser } from 'react-icons/bi'
import { BsPersonWorkspace } from 'react-icons/bs'
import './nav.scss'


const Nav = () => {

  const [active, setActive] = useState('#');

  return (
    <nav>
      <a href='#' className={active === '#' ? 'active' : ''}><AiFillHome onClick={() => { setActive('#') }} /></a>
      <a href='#about' className={active === '#about' ? 'active' : ''} onClick={() => { setActive('#about') }}><BiSolidUser /></a>
      <a href='#skills' className={active === '#skills' ? 'active' : ''} onClick={() => { setActive('#skills') }}><BsPersonWorkspace /></a>
      <a href='#contact' className={active === '#contact' ? 'active' : ''} onClick={() => { setActive('#contact') }}><AiFillMessage /></a>
      <a href='#portfolio' className={active === '#portfolio' ? 'active' : ''} onClick={() => { setActive('#portfolio') }}><AiFillRead /></a>
    </nav>
  )
}

export default Nav