import React, { useState, useEffect } from 'react'
import axios from 'axios';
import PorfolioItem from './PorfolioItem'

const PortfolioItemsGrid = ({ data }) => {

  const [repoData, setRepoData] = useState(null)
  const username = 'ramalt';
  useEffect(() => {
    const fetchRepoData = async () => {
      try {
        const response = await axios.get(`https://api.github.com/users/${username}/repos`)

        const sortedData = response.data.sort((a, b) => {
          return new Date(b.updated_at) - new Date(a.updated_at);
        });
        setRepoData(sortedData.slice(0,30))
      } catch (error) {

      }
    }

    fetchRepoData();
  }, [username])

  return (


    <div className='table-wrapper'>
      <table className='portfolio__table'>
        {repoData ? (
          repoData.map((repo) => (
            <PorfolioItem
              key={repo.id} // Eğer verinin benzersiz bir alanı varsa onu kullanabilirsiniz
              name={repo.name}
              url={repo.html_url}
              description={repo.description}
              language={repo.language}
            />
          ))
        ) : (
          <tr>
            <td colSpan="4">Yükleniyor...</td>
          </tr>
        )}
      </table>
    </div>




  )
}

export default PortfolioItemsGrid