import React from 'react'
import { useTranslation } from 'react-i18next'

const CTA = () => {
    const {t} = useTranslation();
    return (
        <div className='cta'>
            {/* TODO: CV href */}
            <a href="" download className='btn'>{t('common.headerCVButton')}</a>
            <a href="#contact" className='btn btn-primary-gradient'>{t('common.headerConnectButton')}</a>
        </div>
    )
}

export default CTA