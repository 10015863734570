import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from './Config/i18n';

import Header from './Components/header/Header';
import Skills from './Components/skills/Skills';
import Portfolio from './Components/portfolio/Portfolio';
import About from './Components/about/About';
import Contact from './Components/contact/Contact';
import HeaderSocials from './Components/nav/HeaderSocials';
import Nav from './Components/nav/Nav';

import './App.scss';

const themes = {
  1: "linear-gradient(135deg, #202020, #000000, #ffff8e)",
  2: "linear-gradient(25deg, #202020, #d36b10, #2eaded, #202020)",
  3: "linear-gradient(25deg, #2c0404, #433600, #200234, #3c0202)",
  4: "linear-gradient(135deg,  #202020, #d4d628, #4fd2b8)",
}

function App() {

  const { t } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const [backgroundColor, setBackgroundColor] = useState();

  const changeBackground = (color) => {
    setBackgroundColor(themes[color]);
  };


  return (
    <>

      <div className="settings-wrapper">

        <div className='localization-wrapper'>
          <button onClick={() => changeLanguage('en')} className='btn-localization'>EN</button>
          <button onClick={() => changeLanguage('tr')} className='btn-localization'>TR</button>
        </div>

        <div className="theme-buttons-wrapper">
          <button onClick={() => changeBackground(1)} className='btn-theme' title="Nickel" />
          <button onClick={() => changeBackground(2)} className='btn-theme' style={{ backgroundColor: 'rgb(141, 49, 3)' }} title="Florida" />
          <button onClick={() => changeBackground(3)} className='btn-theme' style={{ backgroundColor: 'rgb(73, 6, 6)' }} title="Ambition" />
          <button onClick={() => changeBackground(4)} className='btn-theme' style={{ backgroundColor: 'rgb(105, 114, 7)' }} title="Lemon Haze" />

        </div>
      </div>

      <div className="App" style={{ background: backgroundColor }}>

        <Header />
        {/* <Skills /> */}
        <Portfolio />
        <About />
        <Contact />
        <HeaderSocials />
        <Nav />
      </div>
    </>
  );


}

export default App;
