import React from 'react'
import REPO_THUMB from '../../assets/githubthumb.jpg'

const PorfolioItem = ({ name, url, description, language }) => {
    return (
        <>
            <tr className='portfolio__item'>
                <a href={url} >
                    <td className='portfolio__item-image-wrapper'><img src={REPO_THUMB} alt="" /></td>
                    <td><h4>{name.slice(0, 22) + "..."}</h4></td>
                    <td className='portfolio__item-description'>{description && description.slice(0, 35) + "..." }</td>
                    <td>{language}</td>
                </a>
            </tr>

            <hr />

        </>

    )
}

export default PorfolioItem