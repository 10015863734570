import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Dil dosyalarının yolu
import translationTR from '../Locales/tr/translation.json';
import translationEN from '../Locales/en/translation.json';

i18n
  .use(initReactI18next) 
  .init({
    resources: {
      en: {
        translation: translationEN,
      },
      tr: {
        translation: translationTR,
      },
    },
    lng: 'en', // Varsayılan dil
    fallbackLng: 'tr', // Varsayılan dilde metin yoksa kullanılacak dil
    interpolation: {
      escapeValue: false, // HTML etiketlerini yoksay
    },
  });

export default i18n;



