import React from 'react'
import { useTranslation } from 'react-i18next'
import './contact.scss'

const Contact = () => {
  const { t } = useTranslation();
  return (
    <section id='contact'>
      <h2>{t('contact.title')}</h2>
    </section>
  )
}

export default Contact