import React from 'react'
import { BsLinkedin, BsGithub, BsSpotify } from 'react-icons/bs'
const HeaderSocials = () => {
    return (
        <div className='header__socials'>
            <a href="https://www.linkedin.com/in/ramazan-altuntepe-3458461a3" target="_blank"><BsLinkedin /></a>
            <a href="https://github.com/ramalt" target="_blank"><BsGithub /></a>
            <a href="https://open.spotify.com/user/4pen94xxlz9ifkgcv4edr6g9y?si=1dce898ee4a9489a&nd=1" target="_blank"><BsSpotify /></a>
        </div>
    )
}

export default HeaderSocials